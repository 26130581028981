<template>
  <div class="page-bg">
    <el-carousel>
      <el-carousel-item>
        <div class="intro-text page-container">
<!--          <h2>{{ item.info1 }}</h2>-->
<!--          <h3>{{ item.info2 }}</h3>-->
        </div>
      </el-carousel-item>
<!--      <el-carousel-item>-->
<!--        <div class="intro-text intro-text1 page-container"></div>-->
<!--      </el-carousel-item>-->
    </el-carousel>
    <div class="pro-container pro-left move-wrap">
      <div class="pro-intro">
        <h4>Product ＆ Services</h4>
        <h5>自主開発の先端AI技術を活用し、教育、医療、金融など多くの業界に革新的サービスを提供し、客様がより大きな価値を実現できるよう支援します。</h5>
      </div>
      <div ref="scrollBox" class="pro-img move-text-img">
        <img src="~@/assets/images/index/services.jpg">
<!--        <div ref="scrollWhite" class="scroll-white-div">-->
<!--          <p class="scroll-white-text scroll-text"> Innovation and Value Creation With AI/ AIでイノベーションを起こし、価値を創造する Innovation and Value Creation With AI/ AIでイノベーションを起こし、価値を創造する Innovation and Value Creation With AI/ AIでイノベーションを起こし、価値を創造する Innovation and Value Creation With AI/ AIでイノベーションを起こし、価値を創造する</p>-->
<!--        </div>-->
      </div>
<!--      <div class="move-text-container">-->
<!--        <p class="move-text scroll-text"> Innovation and Value Creation With AI/ AIでイノベーションを起こし、価値を創造する Innovation and Value Creation With AI/ AIでイノベーションを起こし、価値を創造する Innovation and Value Creation With AI/ AIでイノベーションを起こし、価値を創造する Innovation and Value Creation With AI/ AIでイノベーションを起こし、価値を創造する</p>-->
<!--      </div>-->
    </div>
<!--    <div class="pro-container pro-infos">-->
<!--      <div class="pro-img img-left">-->
<!--        <p>-->
<!--          <img src="~@/assets/images/index/LingoMate.png">-->
<!--        </p>-->
<!--      </div>-->
<!--      <div class="pro-info right-info no-left">-->
<!--        <p class="pro-name">APP: Lingo Mate</p>-->
<!--        <p class="pro-intros">非常にプロフェッショナルで面白いAIバーチャル英会話アプリです。<br/>日本語と英語音声認識および合成技術に加え、言語教育に特化した最先端の対話生成AIエンジンも搭載され、実際の英語会話シーンを完全にシミュレートできます。<br/>Lingo Mateは本物の英語講師に代わって、あなたに時間、場所問わず楽しく効率的に英語力を向上させることを実現できます。</p>-->
<!--        <div class="more-btn" @mouseenter="handleHover(true, 'app')" @mouseleave="handleHover(false, 'app')" @click="openPage('app')">-->
<!--          <div class="btn-text">-->
<!--            <span ref="appText1">Detail</span>-->
<!--            <span ref="appText2">Detail</span>-->
<!--          </div>-->
<!--          <div class="btn-image">-->
<!--            <p ref="appBtn1">-->
<!--              <img src="~@/assets/images/index/see_more_active.png">-->
<!--            </p>-->
<!--            <p ref="appBtn2">-->
<!--              <img src="~@/assets/images/see_more.png">-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="pro-container pro-infos">
      <div class="pro-img img-left">
        <div>
          <img src="~@/assets/images/index/AI_eng.png">
        </div>
      </div>
      <div class="pro-info right-info">
        <p class="pro-name">Platform: Dolphin AI Cloud</p>
        <p class="pro-intros">開発向け音声認識&合成、対話モジュールなどのCloud APIサービスです。<br/>自社開発の日英中など20以上言語対応の音声認識＆合成＆対話エンジンをCloud APIサービスとして提供し、開発者が機械学習のような専門技術を知らなくても高精度の音声対話アプリケーションを開発することができる。 各種APIは様々な開発言語に対応していて、On-Premise構成でも相談可能です。</p>
        <div class="more-btn" @mouseenter="handleHover(true, 'engine')" @mouseleave="handleHover(false, 'engine')" @click="openPage('voice')">
          <div class="btn-text">
            <span ref="engineText1">Detail</span>
            <span ref="engineText2">Detail</span>
          </div>
          <div class="btn-image">
            <p ref="engineBtn1">
              <img src="~@/assets/images/index/see_more_active.png">
            </p>
            <p ref="engineBtn2">
              <img src="~@/assets/images/see_more.png">
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="pro-container pro-infos">
      <div class="pro-info right-info no-right">
        <p class="pro-name">Product: Smart Oral Evaluation</p>
        <p class="pro-intros">英語発音自動評価システムです。<br/>先生など属人的な英語会話評価は主観性強く、時間がかかり、効率が悪いといった欠点があり、発音評価システム採用より、生徒たち英語勉強の効率化と成果の可視化を根本的に変えることができます。当システムは教育のDX化＆AI導入の強力なツールと大きく期待できます。</p>
        <div class="more-btn" @mouseenter="handleHover(true, 'dolphin')" @mouseleave="handleHover(false, 'dolphin')" @click="openPage('soe')">
          <div class="btn-text">
            <span ref="dolphinText1">Detail</span>
            <span ref="dolphinText2">Detail</span>
          </div>
          <div class="btn-image">
            <p ref="dolphinBtn1">
              <img src="~@/assets/images/index/see_more_active.png">
            </p>
            <p ref="dolphinBtn2">
              <img src="~@/assets/images/see_more.png">
            </p>
          </div>
        </div>
      </div>
      <div class="pro-img right-img">
        <div>
          <img src="~@/assets/images/index/dlp_AI.png">
        </div>
      </div>
    </div>
    <div class="pro-container pro-left last-intro">
      <div class="pro-img">
        <img src="~@/assets/images/index/intro.jpg">
      </div>
      <div class="pro-intro">
        <h4>Brand Story</h4>
        <h5>
          <p>Dolphin（ドルフィン）は、可愛く社交的でありながら遠海の広い場所において活動する海洋動物でございます。</p>
          <p>ドルフィンは、頭がよく、音声の探知能力が抜群で、音声コミュニケーション能力と認知能力を生かせながら、素晴らしい芸が演じられ、人類に非常に愛されております。</p>
          <p>ドルフィンの学習能力と社会的機能をヒントに、我が会社を「Dolphin AI」と名付けました。</p>
        </h5>
        <div class="more-btn" @mouseenter="handleHover(true, 'company')" @mouseleave="handleHover(false, 'company')" @click="openPage('/company?id=Weare')">
          <div class="btn-text">
            <span ref="companyText1">Detail</span>
            <span ref="companyText2">Detail</span>
          </div>
          <div class="btn-image">
            <p ref="companyBtn1">
              <img src="~@/assets/images/index/see_more_active.png">
            </p>
            <p ref="companyBtn2">
              <img src="~@/assets/images/see_more.png">
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="zhuanli">
      <h4>Data Security</h4>
      <div>
        <p><img src="@/assets/images/index/Security1.png" alt=""><span>ISO 27001</span></p>
        <p><img src="@/assets/images/index/security2.svg" alt=""><span>SSL/TLS</span></p>
        <p><img src="@/assets/images/index/security3.svg" alt=""><span>GDPR</span></p>
        <p><img src="@/assets/images/index/security4.svg" alt=""><span>APPI</span></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  data () {
    return {
      bannerList: [
        {
          info1: 'Vision/ビジョン',
          info2: 'Innovation and Value Creation With AI/ AIでイノベーションを起こし、価値を創造する'
        },
        // {
        //   info1: 'Lingo Mate',
        //   info2: '最高のAI英会話練習パートナー'
        // }
      ]
    }
  },
  watch: {
    $route(to, from) {
      if (from !== '/') {
        this.$refs.scrollWhite.style.left = -(this.$refs.scrollBox.offsetLeft) + 10 + 'px'
      }
    }
  },
  mounted () {
    window.onscroll = () => {
      this.pageScroll()
    }
    window.onresize = () => {
      this.$refs.scrollWhite.style.left = -(this.$refs.scrollBox.offsetLeft) + 10 + 'px'
    }
  },
  beforeDestroy () {
    window.onscroll = null
    window.onresize = null
  },
  methods: {
    handleHover (bol, refName) {
      let text1 = this.$refs[refName+'Text1']
      let text2 = this.$refs[refName+'Text2']
      let btn1 = this.$refs[refName+'Btn1']
      let btn2 = this.$refs[refName+'Btn2']
      if (bol) {
        text1.classList.add("text-move-enter")
        text1.classList.add("fade")
        text2.classList.add("text-move-enter")
        btn1.classList.add("btn-move-enter")
        btn2.classList.add("btn-move-enter")
        text1.classList.remove("text-move-leave")
        text2.classList.remove("text-move-leave")
        text2.classList.remove("fade")
        btn1.classList.remove("btn-move-leave")
        btn2.classList.remove("btn-move-leave")
      } else {
        text1.classList.add("text-move-leave")
        text2.classList.add("text-move-leave")
        text2.classList.add("fade")
        btn1.classList.add("btn-move-leave")
        btn2.classList.add("btn-move-leave")
        text1.classList.remove("text-move-enter")
        text1.classList.remove("fade")
        text2.classList.remove("text-move-enter")
        btn1.classList.remove("btn-move-enter")
        btn2.classList.remove("btn-move-enter")
      }
    },
    openPage (url) {
      if (url === 'app') {
        window.open(this.$lingomate, '_blank')
      } else if (url === 'soe') {
        window.open(this.$dolphinsoe, '_blank')
      } else if (url === 'voice') {
        window.open(this.$dolphinvoice, '_blank')
      } else {
        this.$router.push(url)
      }
    },
    pageScroll () {
      let imgs = document.getElementsByClassName('pro-img')
      let h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      for (let i = 0; i < imgs.length; i++) {
        let img1Top = imgs[i].getBoundingClientRect().top
        if (img1Top < h-300) {
          imgs[i].classList.add("scroll-top")
          imgs[i].classList.remove("scroll-bottom")
        } else {
          imgs[i].classList.add("scroll-bottom")
          imgs[i].classList.remove("scroll-top")
        }
        // if (img1Top < 300) {
        //     imgs[i].classList.add("scroll-top")
        //     imgs[i].classList.remove("scroll-bottom")
        // }
        // if (img1Top < -715 || img1Top > 300) {
        //     imgs[i].classList.add("scroll-bottom")
        //     imgs[i].classList.remove("scroll-top")
        // }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.el-carousel {
  color: #fff;
  /deep/ .el-carousel__container {
    height: 560px!important;
  }
  /deep/ .el-carousel__indicator .el-carousel__button {
    width: 16px;
    height: 16px;
    border-radius: 8px;
  }
  .el-carousel__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .intro-text {
      flex: 1;
      height: 100%;
      background: url('../assets/images/index/index_banner1.jpg') no-repeat left top;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h2 {
        font-size: 54px;
        font-weight: 700;
        letter-spacing: 0;
        color: rgba(255, 255, 255, 1);
        text-align: left;
        margin-bottom: 55px;
      }
      h3 {
        font-size: 18px;
        letter-spacing: 0;
        color: rgba(255, 255, 255, 1);
        text-align: left;
      }
      h2, h3 {
        width: 605px;
      }
    }
    .intro-text1 {
      background: url('../assets/images/index/index_banner2.jpg') no-repeat left top;
      background-size: 100% 100%;
    }
  }
}
.pro-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  min-height: 400px;
  padding: 0 360px;
  margin: 0 auto;
  overflow: hidden;
  .pro-intro {
    width: 753px;
    h4 {
      left: 165px;
      font-size: 40px;
      font-weight: 500;
      letter-spacing: 0;
      color: rgba(47, 41, 223, 1);
      text-align: left;
      vertical-align: top;
      margin-bottom: 35px;
    }
    h5 {
      left: 165px;
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 28px;
      color: rgba(60, 60, 60, 1);
      text-align: left;
      vertical-align: top;
      p {
        margin: 15px 0;
      }
    }
  }
  .pro-img {
    display: flex;
    align-items: end;
    width: 479px;
    flex-shrink: 0;
    border-radius: 20px;
    overflow: hidden;
    margin-left: 78px;
    img {
      width: 479px;
      transform: scale(1.2);
    }
  }
  .pro-img.scroll-top >img {
    transform: scale(1);
    transition: transform 0.3s ease;
  }
  .pro-img.scroll-bottom >img {
    transform: scale(1.2);
    transition: transform 0.3s ease;
  }
  .move-text {
    font-size: 28px;
    font-weight: 300;
    letter-spacing: 0;
    color: rgba(68, 82, 240, 1);
    text-align: left;
    vertical-align: top;
    white-space: nowrap;
    width: fit-content;
  }
  .move-text-container {
    height: 116px;
    width: 100%;
    position: absolute;
    left: 11px;
    right: 0;
    bottom: 4px;
  }
}
.pro-infos {
  overflow: initial;
}
.last-intro {
  .pro-img {
    width: auto;
    margin-left: 0;
    margin-right: 78px;
    img {
      width: auto;
    }
  }
}
.move-wrap .pro-img{
  border-radius: 20px 0 0 20px;
}
.move-wrap {
  overflow: hidden;
}
.pro-left {
  padding-top: 80px;
  background: url('../assets/images/grid_bg.png') no-repeat -275px 25px;
  background-size: 100% 100%;
  .pro-img {
    right: 0;
  }
}
.last-intro {
  padding-top: 30px;
  padding-bottom: 85px;
  margin-bottom: 0;
  .pro-img {
    height: 600px;
    border-radius: 0;
    img {
      height: 100%;
      transform: scale(1.2);
    }
  }
  .more-btn {
    margin-top: 35px;
  }
}
.zhuanli {
  width: 100%;
  margin-bottom: 30px;
  background: #fff;
  text-align: center;
  h4 {
    padding-top: 35px;
    margin-bottom: 35px;
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 0;
    color: rgba(47, 41, 223, 1);
    text-align: center;
    vertical-align: top;
  }
  >div {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      display: flex;
      flex-direction: column;
      justify-content: center;
      span {
        margin-top: 10px;
        font-size: 16px;
        line-height: 24px;
        color: rgba(120, 127, 132, 1);
      }
    }
    img {
      width: 120px;
      height: 120px;
      margin: 0 20px;
    }
  }
}
.pro-infos {
  position: relative;
  background-size: 100% 100%;
  .pro-intro {
    position: absolute;
    right: 165px;
  }
  .pro-img {
    width: 558px;
    height: 500px;
    flex-shrink: 0;
    background: url('../assets/images/index/pro_img_bg.png') no-repeat -66px center;
    background-size: auto 100%;
    border-radius: 0;
    overflow: initial;
    div {
      overflow: initial!important;
    }
    img {
      transform: scale(1.2);
      width: 100%;
      height: 100%;
    }
  }
  .pro-img.scroll-top img {
    transform: scale(1);
    transition: transform 0.3s ease;
  }
  .pro-img.scroll-bottom img {
    transform: scale(1.2);
    transition: transform 0.3s ease;
  }
  .right-img {
    width: 600px;
    margin-left: 33px!important;
    margin-right: -25px!important;
    background-position: 294px 0;
    position: relative;
    >div {
      position: absolute;
      right: -2px;
      top: 0;
      overflow: hidden;
      width: 600px;
      height: 399px;
      img {
        width: auto;
      }
    }
  }
}
.pro-info {
  width: 55%;
  top: 90px;
  min-height: 100px;
  background: url('../assets/images/grid_bg.png') no-repeat -70px -20px;
  background-size: 128% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.right-info {
  flex: 1;
}
.right-info.no-left {
  padding-left: 80px;
}
.right-info.no-right {
  padding-right: 0;
}
.pro-name {
  background: url('../assets/images/pro_name.png') no-repeat left 5px;
  background-size: 40px 40px;
  padding-left: 60px;
  height: 50px;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 50px;
  color: #2C2FE4;
  text-align: left;
  vertical-align: middle;
  margin-bottom: 36px;
}
.pro-intros {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
  margin-bottom: 38px;
}
.more-btn {
  width: 175px;
  padding-left: 20px;
  padding-right: 20px;
  height: 54px;
  opacity: 1;
  border-radius: 20px;
  background: linear-gradient(225deg, rgba(87, 120, 255, 1) 0%, rgba(47, 41, 223, 1) 100%);
  cursor: pointer;
  cursor: hand;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btn-text {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    height: 18px;
    overflow: hidden;
  }
  span {
    display: inline-block;
    width: 100%;
    height: 18px;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 18px;
    color: rgba(255, 255, 255, 1);
    text-align: left;
    vertical-align: top;
  }
  .btn-image {
    overflow: hidden;
    width: 22px;
    height: 22px;
    flex-shrink: 0;
    display: flex;
    border-radius: 100%;
  }
  p, p>img {
    width: 22px;
    height: 22px;
    border-radius: 100%;
  }
  .btn-image >p:nth-of-type(1) {
    transform: translateX(-22px);
  }
  .btn-image >p:nth-of-type(2) {
    transform: translateX(-22px);
  }
  .btn-image .btn-move-enter {
    transform: translateX(0)!important;
    transition: transform 0.3s ease;
  }
  .btn-image .btn-move-leave {
    transform: translateX(-22px)!important;
    transition: transform 0.3s ease;
  }
}
.pro-container .img-left {
  position: relative;
  margin-left: 0;
  >p {
    width: 454px;
    position: absolute;
    right: 0;
    top: 40px;
  }
  >div {
    position: absolute;
    left: 30px;
    top: 80px;
    overflow: hidden;
    width: 500px;
  }
}
.move-text-img {
  height: 400px;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.scroll-white-text {
  height: 120px;
}
.scroll-white-div {
  width: 100%;
  height: 120px;
  position: absolute;
  bottom: 0;
  white-space: nowrap;
  left: -1070px;
  >p {
    font-size: 28px;
    font-weight: 300;
    color: #fff;
    width: fit-content;
  }
}
// Detail
.text-move-enter {
  transform: translateY(-18px);
  transition: transform 0.3s linear;
}
.text-move-leave {
  transform: translateY(0);
  transition: transform 0.3s linear;
}
.fade {
  animation: fadeOutUp 0.3s linear; /* 指定动画名称、持续时间、速度曲线和结束状态 */
}
@keyframes fadeOutUp {
  0% { opacity: 1;}
  20% { opacity: 0.8;}
  40% { opacity: 0;}
  60% { opacity: 0;}
  80% { opacity: 0;}
  100% { opacity: 0;}
}
.scroll-text {
  animation: scroll 25s linear infinite;
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
</style>
